@import '~base/search';
@import './global/variables';
@import './global/colors';
@import './mixins/util';
@import 'utilities/swatch';
@import './global/spacers';
@import './global/button';

.pgp-breadcrumb {
    @include media-breakpoint-down(lg) {
        display: inline-block !important;
    }
}

.container .breadcrumb {
    @include media-breakpoint-down(md) {
        padding: rem(12) 0;
    }
    @include media-breakpoint-down(xs) {
        padding-top: 0;
    }
}

.grid-footer {
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.25s linear;

    &.grid-footer-active {
        opacity: 1;
        pointer-events: all;
    }

    .products-viewed {
        color: $black;
    }

    .show-more {
        padding: 0;

        button {
            font-size: $font-14px;
        }
    }
}

.selected-filter-breadcrumbs {
    display: none;
}

/* no search results styles */

.no-results-section {
    .noresults-section {
        justify-content: center !important;

        @include media-breakpoint-up(lg) {
            width: 100%;
        }

        .page-title {
            text-align: center !important;
        }
    }

    .mobile-homepage-quicklinks {
        h3 {
            text-align: center;
            margin-left: 0 !important;
        }

        .mobile-homepage-quicklinks-ctn {
            justify-content: center;
            flex-wrap: wrap !important;
        }
    }

    .search-tip {
        font-size: $font-size14;
        line-height: $font-size20;
    }

    .text-left {
        padding-left: 0;

        @include media-breakpoint-down(md) {
            max-width: 100%;
            flex: 0 0 100%;
        }
    }

    .top-results {
        font-size: $font-size20;
        font-weight: $fontweight-regular;
        line-height: $font-size32;

        .name a {
            font-size: $font-size16;
            line-height: $font-size32;
            color: $black;
        }
        @include media-breakpoint-down(md) {
            font-size: $font-size16;
            line-height: $font-size24;

            .name a {
                font-size: $font-size14;
                line-height: $font-size40;
            }
        }
    }

    .homepage-product-listing {
        width: 100%;
    }
}

.noresults-section {
    .results-count {
        font-size: $font-size14;
        line-height: $font-size20;
    }
}

/* no search results styles end */

.search-results {
    .tab-content {
        @include media-breakpoint-down(xs) {
            padding-left: 1rem;
            padding-right: 1rem;
        }
    }

    .desktop-sort {
        select.custom-select {
            border: 1px solid #bec0c3 !important;
            padding: rem(12);
            padding-right: rem(28); //extra padding to preventtext flowing over up/down icon
            line-height: 1rem;
            border-radius: rem(4);
            font-weight: $fontweight-bold;
        }

        .form-group.form-group-select::after {
            top: rem(14);
            right: 0.5rem;
            background-size: 16px 14px !important;
            background-repeat: no-repeat;
        }

        .form-group-select {
            &.active:not(:has(.sort-order.open)) {
                &::after {
                    background-image: url('../../images/down-icon.svg');
                }
            }
        }

        @include media-breakpoint-down(md) {
            display: none;
        }
    }

    .filters-hideshow-btn {
        cursor: pointer;
        color: $black;

        @include media-breakpoint-up(lg) {
            right: 15.5rem;
        }

        .filter-hide,
        .filter-show {
            display: none;
            font-size: $font-14px;
            color: $black;
            cursor: pointer;

            button {
                font-weight: $fontweight-bold;
                border: 1px solid #bec0c3;
                border-radius: rem(4);
                padding: rem(12);
                line-height: 1;
            }

            &.active {
                display: inline-block;
            }
        }
    }

    .mobilecategory {
        @include media-breakpoint-down(md) {
            position: relative;
            width: 100%;
            height: 22px;

            .mobile-category {
                overflow-x: scroll;
                position: absolute;
                -ms-overflow-style: none;
                max-width: calc(100vw - 32px);

                &::-webkit-scrollbar {
                    display: none;
                }

                .collapsible-sm {
                    .card-header {
                        display: none;
                    }

                    .card-body,
                    .content {
                        display: inline-block;
                    }
                }

                .refinement {
                    display: none;

                    &.refinement-categories,
                    &.guestrefinement-categories {
                        display: inline-block;
                        border: 0;

                        .card-body {
                            .attr-refine {
                                display: none;
                            }

                            .content {
                                margin: 0;
                                padding: 0;
                                white-space: nowrap;

                                li {
                                    display: inline-block;

                                    button {
                                        padding-right: 1.5rem;
                                        padding-left: 0.5rem;

                                        span {
                                            text-decoration: underline;

                                            &.selected {
                                                font-weight: $fontweight-semibold;
                                                text-decoration: none;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .page-title {
        color: $black;

        .page-category {
            font-size: $font-size24;
            line-height: $font-size32;

            &.no-results {
                font-size: $font-size20;
                font-weight: $fontweight-medium;
                line-height: $font-size32;

                .search-key {
                    font-weight: $fontweight-bold;
                }
                @include media-breakpoint-down(md) {
                    font-size: $font-size16;
                    line-height: $font-size24;
                }
            }
        }

        .pgp-count {
            font-size: $font-size14;
            line-height: $font-size20;
        }
    }

    .refinement-bar {
        .page-category {
            font-family: $fontweight-semibold;
        }

        .pgp-count {
            font-size: $font-size24;
            line-height: $font-size20;
            font-weight: 700;
        }

        .result-count {
            font-size: $font-size24;
            font-weight: 700;
        }
    }

    #filter-tooltip-info {
        position: fixed;
        z-index: 1002;
        width: 17rem;
        color: white;
        font-size: 12px;
        background-color: #192733;
        border-radius: 5px;
        padding: 10px;
    }

    .brk-wth {
        word-wrap: break-word;
    }

    .sort-order {
        cursor: pointer;
    }

    .refinement-tabs {
        padding: 0.6875rem 0;
        display: none;

        &.d-show {
            display: inline-block !important;
            width: 100%;
        }

        .refinement-bar {
            margin: 0;
            height: 100%;
        }

        @include media-breakpoint-down(md) {
            display: none !important;
        }

        .selected-refinements-gridtop {
            .selected-value {
                margin-right: 2rem;

                &:last-child {
                    margin-right: 1.5rem;
                }

                .filter-tabs {
                    margin-right: 0.875rem;
                    pointer-events: none;
                }

                .clear-icon {
                    width: 0.5rem !important;
                    height: 0.5rem !important;
                    cursor: pointer;
                }
            }
        }
    }

    .grid-list {
        @include media-breakpoint-down(xs) {
            display: none;
        }

        .grid-view,
        .list-view {
            .gridview-inactive,
            .listview-inactive,
            .listview-active,
            .gridview-active {
                opacity: 0;
                left: 0;

                &.active {
                    opacity: 1;
                }
            }

            .listview-active,
            .gridview-inactive {
                z-index: -1;
            }
        }
    }

    .product-tile {
        position: relative;
        color: $black !important;

        // On changing breakpoint values also adapt determineRelevance() in wishlistHelper.js.
        @include media-breakpoint-up(lg) {
            &:hover {
                .image-container {
                    .wishlistTile {
                        position: absolute;
                        top: 2%;
                        right: 3%;
                        display: inline-block;
                    }
                }
            }
        }

        .customize-tile {
            font-size: $font-size12;
            color: $silver-grey;
            text-transform: uppercase;
        }

        .colors-count {
            height: rem(24);

            @include media-breakpoint-down(lg) {
                display: inline-block;
                pointer-events: none;
                text-decoration: none;
            }
            @include media-breakpoint-up(lg) {
                text-decoration: underline;
            }
        }

        .wishlistTile {
            .wishlist-inactive,
            .wishlist-active {
                display: none;

                &.active {
                    display: inline-block;
                }
            }
        }

        .arTryOn-badge {
            position: absolute;
            right: 0.45rem;
            bottom: 0.45rem;
            border-radius: 0.625rem;
            padding: 0.25rem 0.35rem;
            pointer-events: none;
            touch-action: none;
        }

        .image-container {
            background-color: #f1f1f1;
            padding-top: 100%;
            overflow: visible;

            .wishlistTile {
                display: none;
            }

            .next-main-image {
                position: absolute;
                top: 0;

                img {
                    opacity: 0;
                }

                img.lazypreload {
                    display: none;
                }
            }

            a {
                width: 100%;
                position: absolute;
                top: 0;
                height: 100%;
            }

            @include media-breakpoint-up(lg) {
                &:hover {
                    .main-image {
                        display: none;
                    }

                    .next-main-image {
                        img {
                            opacity: 1;
                            display: block;
                        }
                    }
                }
            }
        }

        .pgp-grid {
            .pname,
            a {
                font-size: $font-size14 !important;
                color: $black !important;
            }

            .price {
                .price-value {
                    .sales {
                        font-size: $font-size14 !important;
                        color: $black !important;
                        font-weight: $fontweight-light !important;
                    }

                    .strike-through {
                        color: $nb-grey-strike-through !important;
                    }
                }

                .tax-included {
                    font-size: $font-size14 !important;
                    color: $black !important;
                    font-weight: $fontweight-light !important;
                }
            }

            .discount.percentOff {
                font-size: $font-size14 !important;
                font-weight: $fontweight-light !important;
            }

            .category-name {
                color: $black;
                font-size: 0.75rem;
                @include media-breakpoint-down(lg) {
                    margin-bottom: rem(4);
                }
            }
        }

        .promotions {
            .callout {
                font-size: $font-size14 !important;
                font-weight: $fontweight-light !important;
            }
        }

        .badges {
            position: absolute;
            top: rem(6);

            .sub-badges {
                color: $white;
                font-size: rem(14);
                line-height: rem(16);
                background-color: $black;
                height: rem(24);

                @include media-breakpoint-down(sm) {
                    font-size: rem(12);
                    padding: 6px !important;
                }
            }
        }

        .tile-badges {
            .sub-badges {
                color: $orange;
                font-size: rem(14);
            }
        }

        .color-swatches {
            font-size: 0;
            position: relative;
            margin-bottom: 0.5rem;

            @include media-breakpoint-up(lg) {
                height: 2.5rem;
            }

            .swatches {
                overflow: hidden;
                white-space: nowrap;
                width: calc(100% - 65px);

                @include media-breakpoint-down(sm) {
                    width: calc(100% - 30px);
                    margin-left: 33px !important;
                }

                .slick-disabled {
                    button {
                        opacity: 0.4;
                    }
                }

                .slick-slide {
                    padding: 0;
                }

                .slick-list.draggable {
                    margin-left: -40px;
                }

                @include media-breakpoint-up(lg) {
                    .prev.slick-arrow + .slick-list.draggable {
                        margin-left: 0;
                    }
                }

                .getproduct {
                    width: max-content;

                    .sildes {
                        display: inline-block;
                        padding: 0.25rem !important;

                        &.inactive {
                            display: none;
                        }

                        &:focus {
                            outline: none;
                            box-shadow: none;
                        }

                        &:focus-visible {
                            img {
                                border: 2px solid $black;
                            }
                        }
                    }

                    .selected {
                        img {
                            border: 1px solid #000;
                        }
                    }
                }

                .prev,
                .next {
                    button {
                        background-color: transparent;
                        border: 0;
                        background-image: url('../images/down-icon.svg');
                        background-repeat: no-repeat;
                        background-size: 1rem;
                        background-position: center;
                        width: 2.25rem;
                        height: 2.4rem;

                        &:disabled {
                            opacity: 0.4;
                            cursor: auto;
                        }
                    }
                }

                .prev {
                    height: 0;
                    overflow-y: hidden;

                    @include media-breakpoint-up(lg) {
                        height: auto;
                        overflow: hidden;
                        position: absolute;
                        top: 3px;
                        left: -38px;
                        background-color: #f1f1f1;

                        button {
                            transform: rotate(90deg);
                        }
                    }
                }

                .next {
                    height: 0;
                    overflow-y: hidden;

                    @include media-breakpoint-up(lg) {
                        height: auto;
                        overflow: hidden;
                        position: absolute;
                        right: -30px;
                        top: 0%;
                        background-color: #f1f1f1;
                        border: 3px white solid;

                        button {
                            transform: rotate(270deg);
                        }
                    }

                    &.slick-arrow {
                        &.slick-disabled {
                            display: none;
                        }
                    }
                }
            }

            .swatch-circle {
                width: 2.25rem;
                height: 2.25rem;
                border: 0;
            }
        }
    }

    .refinement-bar {
        margin-top: 1.5rem;

        @include media-breakpoint-up(lg) {
            position: sticky;
            top: 90px;
            height: calc(100vh - 160px);

            &.col-lg-3 {
                display: block !important;

                &.hidden {
                    display: none !important;
                }
            }
        }

        @include media-breakpoint-down(md) {
            transform: translateY(120%);
            transition: transform 0.5s cubic-bezier(0.64, 0.04, 0.35, 1);
            z-index: 1001;
            height: 84%;
            overflow: hidden;
            background-color: #fff;
            display: inline;
            left: 0;
            position: fixed;
            top: 0;
            width: 100%;
            border-radius: 1rem 1rem 0 0;

            &.hide-show-filter {
                .refinement-categories,
                .guestrefinement-categories {
                    &:not(:has(.my-fit)) {
                        display: none !important;
                    }

                    .card-body {
                        ul {
                            display: none !important;

                            &.myfit-mobile {
                                display: block !important;
                            }
                        }
                    }
                }

                .refinement-content {
                    max-height: 90%;
                    overflow-x: hidden;
                    overflow-y: auto;
                    padding-bottom: 2rem;
                    padding-right: 0.5rem;
                }

                .mobile-footer-count {
                    position: fixed;
                    bottom: 0;
                    left: 0;
                    padding: 0 1rem;
                    margin: 0 auto;
                    background-color: $white;

                    .pgp-count {
                        font-size: 0.875rem;
                        line-height: 1rem;
                        font-weight: 500;
                    }
                }
            }

            .filter-header {
                .header-bar {
                    border: 0;

                    button {
                        position: absolute;
                        top: 1.2rem;
                        right: 1rem;
                        font-size: 1.5rem;
                        line-height: 0;
                        font-weight: $fontweight-light;
                        width: rem(30);
                        height: rem(30);
                        background-color: $fog-white;
                        border-radius: 4px;
                    }
                }
            }
        }

        .header-bar {
            background-color: transparent;
        }

        .mobile-footer-count {
            button {
                color: $white;
                font-weight: $fontweight-semibold;
            }
        }

        .refinements {
            .card {
                border: 0;

                .refinment-myfit {
                    border-top: 1px solid $silver-grey;
                }

                .myfit-unset {
                    button {
                        opacity: 0.5;
                        pointer-events: none;
                    }

                    label[for="myfit-unset"] {
                        pointer-events: none !important;
                    }
                }

                &:last-child {
                    border-bottom: 0.0625rem solid $silver-grey;

                    @include media-breakpoint-down(sm) {
                        border: 0;
                        margin-bottom: 4rem !important;
                    }
                }

                &.active {
                    a {
                        background-image: url('../images/minus-icon.svg');

                        &.Edit-myfit,
                        &.set-myfit {
                            background-image: none;
                        }
                    }

                    .title {
                        &::after {
                            content: "";
                            display: none;
                        }
                    }

                    .card-body {
                        @include media-breakpoint-down(lg) {
                            margin: 0.8rem 0 0 !important;
                        }

                        .content {
                            display: flex;
                            flex-wrap: wrap;
                            margin: 0;
                        }
                    }

                    .selected-refinements {
                        display: none;
                    }
                }

                &.refinement-categories,
                &.guestrefinement-categories {
                    .card-header,
                    .plus-minus {
                        display: none;
                    }

                    .card-body,
                    .content {
                        display: inline-block;
                    }
                }

                &.refinement-categories,
                &.guestrefinement-categories {
                    .card-body {
                        .content {
                            padding: 0;
                            margin: 0;

                            button {
                                text-align: left;
                                width: 100%;
                                display: inline-block;
                                padding: 0;
                                margin: 0 0 0.5rem;

                                span {
                                    text-decoration: underline;

                                    &.selected {
                                        font-weight: $fontweight-semibold;
                                        text-decoration: none;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .values {
                .fa {
                    display: none;
                }
            }

            .card-header {
                position: relative;
                border-top: 0.0625rem solid $silver-grey;
                border-bottom: 0;
                padding: 1rem 0 0 !important;

                @include media-breakpoint-down(lg) {
                    padding: 0;
                }

                .title {
                    padding: 0;
                    line-height: 1.25rem;

                    &::after {
                        content: "";
                        display: none;
                    }

                    .filter-tooltip {
                        position: relative;
                        display: inline-block;
                        padding: 0 5px;
                        text-align: center;
                        top: -1px;

                        .icon {
                            pointer-events: none;
                        }
                    }
                }

                .filter-title {
                    font-weight: 300;
                    font-size: 16.8px;
                    letter-spacing: 0.2px;
                    text-shadow: 0 0 0 #403b3b;
                }

                a {
                    &.plus-minus {
                        width: 1rem;
                        height: 0.9375rem;
                        background-repeat: no-repeat;
                        background-size: 1rem 0.9375rem;
                        display: inline-block;
                    }

                    background-image: url('../images/plus-icon.svg');
                    position: absolute;
                    right: 0;
                    top: 50%;
                }
            }

            .no-results {
                .card-body {
                    ul {
                        &.content {
                            display: block;
                        }
                    }
                }
            }

            .card-body {
                padding: 0;
                margin: 0;
                width: 100%;

                ul {
                    &.content {
                        display: none;
                    }

                    &.selected-refinements {
                        margin: 1rem 0 0;

                        .selected-value {
                            font-size: $font-size12;
                            line-height: 1rem;
                            color: $black;
                            padding-bottom: 1rem;
                        }
                    }
                }
            }

            .attributes {
                width: 100%;

                button,
                a {
                    color: $black;
                    font-size: $font-size14;
                    font-weight: $fontweight-light;
                    line-height: 1.5rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: fit-content;

                    .custom-control,
                    .custom-control-input {
                        width: 1rem;
                        height: 1rem;
                        left: 0;
                        top: 0;
                    }

                    .custom-control-label {
                        top: 0;

                        &::after,
                        &::before {
                            left: 0;
                            top: 0.1rem;
                            border-radius: 0;
                            background: transparent;
                        }
                    }

                    &.refinement-link {
                        background-image: none;
                    }

                    &.selected {
                        .custom-control-label::after {
                            background-image: url('../../images/selected.svg');
                        }
                    }
                }
            }

            [class$="width"],
            [class$="size"] {
                .values {
                    flex-direction: unset !important;

                    .attributes {
                        border: 0.125rem solid $white-smoke;
                        background: $white;
                        margin: 0 0.5rem 0.5rem 0;
                        float: left;
                        width: auto;

                        button {
                            text-align: center;

                            &.selected {
                                background-color: $black;
                                color: $white;
                            }
                        }

                        .custom-control {
                            display: none;
                        }
                    }

                    .variation-attribute {
                        border: 0.125rem solid $white-smoke;
                        padding: 0;
                        background: $white;
                    }

                    .filter-boolean {
                        border: 0.063rem solid #abb0b4;
                        border-radius: 0.4rem;
                    }

                    span.trimtext {
                        line-height: 1em;
                    }
                }
            }

            [class$="width"] {
                .card-body {
                    button,
                    a {
                        width: auto;
                    }

                    .refine-count {
                        display: none;
                    }
                }
            }

            :not([class$="size"]),
            :not([class$="width"]) {
                .attributes {
                    margin: 0 0 0.4rem;
                }

                .values {
                    flex-direction: column;
                }

                button,
                a {
                    padding: 0;
                }
            }

            .refinement-color,
            .refinement-refinementcolor {
                .content {
                    flex-direction: row;
                }

                .color-attribute {
                    margin: 0 0 0.6rem;
                    width: 50%;
                    @include media-breakpoint-down(md) {
                        width: auto;
                    }

                    .selected .color-border {
                        border: 1px solid $black;

                        .color-value {
                            width: 0.625rem;
                            height: 0.625rem;
                        }
                    }

                    .color-border,
                    .color-value {
                        display: block;
                        width: 1rem;
                        height: 1rem;
                        border: none;
                        border-radius: 1.25em;
                        position: relative;

                        &.selected::after {
                            content: none;
                        }

                        &.disabled {
                            opacity: 0.2;
                        }
                    }

                    &[data-hex="FFFFFF"] {
                        .color-border,
                        .color-value {
                            border: 1px solid $black;
                        }

                        .selected {
                            .color-border {
                                .color-value {
                                    border: 1px solid $silver-grey;
                                }
                            }
                        }
                    }
                }

                .filter-color {
                    border: 0.063rem solid #abb0b4;
                    border-radius: 0.4rem;
                    padding: 8px;
                    padding-left: 16px;
                    padding-right: 16px;
                    margin: 0 0.2rem 0.625rem;
                    @include media-breakpoint-up(lg) {
                        width: auto;
                    }
                }
            }

            .price {
                button {
                    color: $black;
                    font-size: $font-14px;
                    font-weight: $fontweight-light;
                }
            }

            .sort-filter-row {
                @include media-breakpoint-down(md) {
                    flex-direction: row !important;
                }
            }

            .filter-row {
                flex-flow: row;
                flex-direction: row !important;
            }

            .filter-row-items {
                border: 0.063rem solid #abb0b4 !important;
                border-radius: 0.4rem;
                padding: 8px;
                padding-left: 16px;
                padding-right: 16px;
                margin: 0 0.5rem 0.5rem 0 !important;
                width: auto !important;

                .filter-checkbox {
                    display: none;
                }
            }

            .filter-row-items-selected {
                button {
                    text-align: center;

                    &.selected {
                        border-radius: 0.25rem !important;
                        background-color: $nb-grey-light !important;
                        color: $nb-black !important;
                    }
                }

                border: 0.125rem solid $nb-black !important;
            }

            .selected-filter-breadcrumbs {
                display: flex;
                flex-wrap: wrap;

                .filter-breadcrumbs-inner {
                    background-color: #fff;
                    position: relative;
                    border-top: 0 solid white;
                    padding-top: 0 !important;
                    align-items: baseline;

                    @include media-breakpoint-down(md) {
                        border-top: 0.0625rem solid $silver-grey;
                        padding: 1rem 0 0 !important;
                    }
                }

                // Based on padding for other filters
                .btn-filter-breadcrumb {
                    margin: 0 0.5rem 0.5rem 0;
                    padding: 4px 12px 4px 16px;
                    border-radius: 4px;
                    background-color: #f2f2f3;
                    border: 0.125rem solid #f2f2f3;
                    line-height: 1.5;

                    &:hover {
                        border-color: black;
                    }

                    font-family: $body-font-family;
                    font-size: 12px;
                }
            }
        }
    }

    .sortBy-mob-filter {
        @include media-breakpoint-down(md) {
            .form-group-select {
                &::after {
                    content: none;
                }
            }
        }

        .sort-by-mobile {
            display: block;

            .brand {
                position: relative;
                left: 0;
                text-align: left;
                margin: 0;
            }

            .form-group.form-group-select::after {
                display: none;
            }
        }

        .form-check-label {
            &::before,
            &::after {
                top: 0;
                left: 0;
            }
        }

        .sort-by-option.active {
            .form-check-label {
                &::after {
                    background-image: url('../../images/active.svg');
                    width: 16px;
                    height: 16px;
                    background-size: cover;
                }
            }
        }
    }

    .grid-header {
        .sort-order.open {
            background-image: url(../../images/up-icon.svg);
        }
    }

    .filter-results {
        font-weight: $fontweight-bold;
        border-color: $black;
        @include media-breakpoint-down(md) {
            line-height: normal;
            width: 100%;
        }
    }

    .mobile-selected-filters {
        overflow-x: scroll;
        white-space: nowrap;

        ul {
            margin-bottom: 0.5rem;

            li {
                margin-right: 0.5rem;

                &:nth-child(3) {
                    z-index: 3;
                }

                button {
                    border-color: #ccc;
                    border-radius: 4px;
                    padding: 0.625rem 0.75rem;
                    height: rem(36);
                    line-height: normal;
                    width: 100%;
                    font-weight: 700;
                }
            }
        }

        .sort-by-mobile {
            display: block;
            position: absolute;
            z-index: 2;
            background: #efefef;
            border-radius: 4px;
            top: 0;
            opacity: 0;
            padding-left: 5px;
            width: 165%;
        }
    }
}

.pgp-grids {
    .pgptiles {
        @include media-breakpoint-up(lg) {
            padding-left: 8px !important; // VQA fixes for desktop
        }

        @include media-breakpoint-down(md) {
            padding: 0 rem(2) !important;
        }
    }

    .product-grid {
        @include media-breakpoint-down(md) {
            padding-top: $nb-spacing-4;
        }
    }

    .grid-header {
        @include media-breakpoint-down(md) {
            margin-top: $nb-spacing-3;
            margin-bottom: $nb-spacing-3;

            .search-heading-grid {
                display: block;

                &::after {
                    content: ':';
                }
            }

            .result-count {
                padding-bottom: 0;
            }
        }
    }

    .pgp-banner-above-grid {
        .experience-commerce_assets-textImageSideBySide > .fluid-image-max-width > .row {
            margin-left: 0;
            margin-right: 0;
        }

        .visual-navigation > .mx-lg-11 {
            margin-right: 0 !important;
            margin-left: 0 !important;
        }
    }

    .grid-filter-breadcrumbs {
        margin-top: -10px;

        .selected-filter-breadcrumbs {
            display: flex;
            flex-wrap: wrap;

            .filter-breadcrumbs-inner {
                background-color: #fff;
                position: relative;
                border-top: 0 solid white;
                padding-top: 0 !important;
                align-items: baseline;
            }

            .btn-filter-breadcrumb {
                margin: 0 0.375rem 0.5rem 0;
                padding: 4px 8px;
                border-radius: 4px;
                background-color: #ebeced;
                border: 0.063rem solid #ebeced;
                line-height: normal;
                font-weight: 500;

                &:hover {
                    border-color: black;
                }

                font-family: $body-font-family;
                font-size: 0.75rem;
            }

            .reset {
                background-color: transparent !important;
                border: 0 !important;
                color: #5c6268;
            }
        }
    }
}

/* Back To Top button styles mobile only */

@include media-breakpoint-down(md) {
    .back-to-top-button {
        position: fixed;
        bottom: rem(12);
        right: rem(20);
        height: rem(48);
        width: rem(48);
        background-color: #000;
        border-radius: rem(56);
        z-index: 1000;

        .up-icon-white {
            position: absolute;
            top: rem(10);
            left: rem(10);
        }
    }
}

/* overriding base styles from _productTiles.scss */
.product-tile {
    .tile-body {
        padding: $nb-spacing-2 0 $nb-spacing-9;
        @include media-breakpoint-down(md) {
            padding: $nb-spacing-1 $nb-spacing-25;
        }

        .tile-body-footer {
            .ratings {
                .bv_main_container {
                    display: none !important;

                    &[aria-label^="1."],
                    &[aria-label^="2."],
                    &[aria-label^="3."],
                    &[aria-label^="4."],
                    &[aria-label^="5."] {
                        display: inline-flex !important;
                    }
                }
            }
        }
    }
}

.bv-tile-height {
    height: 25px;
    padding-right: 0.5rem;
    padding-left: 0.5rem;

    @include media-breakpoint-down(md) {
        height: unset;
    }

    .bv_main_container {
        flex-direction: row !important;

        .bv_stars_component_container {
            padding-right: 1em !important;
        }

        .bv_sub_container {
            .bv_text {
                font-size: 14px !important;
            }
        }
    }

    .bv_incentivized_review_container {
        .incentivized_text {
            color: #767676 !important;
        }
    }
}

.keyboard-focus {
    .color-swatches {
        .prev,
        .next {
            button {
                &:focus {
                    border: 0 0 0 2px #015ecc solid !important;
                }
            }
        }
    }
}

.noresults-section {
    .no-results {
        .no-results-title {
            font-family: 'Proxima Nova', sans-serif;
            font-size: 24px;
            font-weight: 600;
            line-height: 24px;
            text-align: center;
            color: #000;
            margin-bottom: 16px;
        }

        .no-results-message {
            font-family: 'Proxima Nova', sans-serif;
            font-size: 14px;
            font-weight: 400;
            line-height: 18px;
            text-align: center;
            color: #000;
            margin-bottom: 24px;
        }
    }
}

.null-search-box {
    width: 100%;
    height: 52px;
    padding: 8px 0;
    border-radius: 4px;
    background: #ebeced;
    max-width: 830px;
    position: relative;

    .search-field {
        height: 36px;
        padding: 0 50px 0 16px;
        font-size: 18px;
        border: none;
        background-color: #ebeced;
        font-weight: $fontweight-light;

        &::placeholder {
            color: $black;
        }

        &:focus {
            color: $black;
        }
    }

    .search-button {
        right: 7px;
        top: 13px !important;
        cursor: pointer;
        padding: 0;

        svg {
            width: 24px;
            height: 24px;
            color: #666;
            position: relative;
            right: 6px;
            top: 1px;
            padding-bottom: 0.25rem;
            margin-top: 0.125rem;
        }
    }

    .suggestions-wrapper {
        position: unset;

        .suggestions {
            top: 3.2rem;
            right: 0;
            max-width: 830px;
            width: 100%;
            padding: 1rem 0;

            @include media-breakpoint-up(lg) {
                box-shadow: 10px 10px 55px -25px #a4a4a4;
            }

            .container {
                @include media-breakpoint-up(lg) {
                    padding: 0 0 0 2rem;
                    margin: 0;
                }
            }

            .close-search {
                display: none;
            }

            .no-products-found {
                padding-top: 0.625em !important;
            }

            .search-terms-suggestions {
                width: 30%;
            }

            .product-items {
                width: 70%;
                padding: 0;

                .item {
                    padding: 0 0 1rem 8px;
                }

                .see-all {
                    margin-bottom: 0 !important;

                    a {
                        border-radius: 4px;
                    }
                }
            }

            .product-tile {
                display: flex;
                column-gap: 1rem;

                .image-container {
                    width: 40%;
                    padding: 0;

                    a {
                        position: unset;
                    }

                    @include media-breakpoint-up(lg) {
                        &:hover {
                            .main-image {
                                display: block;
                            }
                        }
                    }
                }

                .tile-body {
                    display: flex;
                    flex-direction: column;
                    width: 60%;

                    .price {
                        padding: 0;
                        line-height: 1rem;

                        .sales {
                            padding: 0;
                        }
                    }

                    .promotions .row {
                        div.pl-2.col-lg-7 {
                            max-width: 100%;
                            flex-basis: 100%;
                        }
                    }
                }
            }
        }
    }
}

/* SEO content styles */
.pgp-seo-content a {
    text-decoration: underline;
}
